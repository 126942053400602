<template>
  <div class="dp-body">
    <section class="n_content">
      <section id="n_dsj" class="m aos-init aos-animate" aos="fade-up" aos-duration="600">
        <img
          src="../../../assets/logo.png"
          style="position: absolute; top: -80px; left: 270px; width: 300px"
        />
        <div class="gz_btn">
          <img src="../../../assets/images/icon_dsj_hover.png" />
        </div>
        <dl>
          <dd v-for="(item, index) in pageDataList" :key="index">
            <section class="dd">
              <div class="pic_box">
                <!-- <img :src="item.img" /> -->
                <el-image class="pic" :src="item.img" fit="cover"></el-image>
              </div>
              <div class="text_box">
                <div class="box">
                  <h1>{{ item.title }}<small>年</small></h1>
                  <div class="ar_article">
                    <p v-for="(itemText, indexText) in item.jsonText" :key="indexText">
                      <span style="color: #c00000; font-size: 16px"
                        >{{ itemText.month }}月</span
                      >&nbsp; &nbsp; {{ itemText.text }}<br />
                    </p>
                  </div>
                </div>
              </div>
            </section>
          </dd>
        </dl>
        <div class="clear"></div>
      </section>
    </section>
  </div>
</template>

<script>
import { formatDateYear } from "@/util";
import axios from "axios"; //引入axios
export default {
  name: "",
  data() {
    return {
      pageDataList: [],
    };
  },
  watch: {},
  created() {},
  mounted() {
    this.queryTitleId();
  },
  methods: {
    queryTitleId() {
      let lang = localStorage.getItem("lang") || "CN";
      axios
        .get("http://edu.siwonet.com:8099/queryTitleId", {
          params: {
            language: lang,
            titleId: this.$route.query.nodeId,
          },
        })
        .then((res) => {
          if (res.data.code == 0) {
            if (res.data.data.pageDatas) {
              this.pageDataList = res.data.data.pageDatas;
              this.pageDataList.map((item) => {
                item.jsonText = JSON.parse(item.jsonText);
                item.title = formatDateYear(item.title);
              });
            }
          } else {
            this.$message.error(data.msg);
          }
        });
    },
  },
  computed: {},
  filters: {},
};
</script>

<style lang="less" scoped>
.dp-body {
  padding-top: 50px;
}
.gz_btn {
  position: absolute;
  top: -7px;
  left: 50%;
  margin-left: -6.5px;
}
.gz_btn:after {
  content: "";
  width: 13px;
  height: 13px;
  background: rgba(216, 9, 48, 0.2);
  animation: fd_sx 3s linear infinite;
  display: block;
  border-radius: 50%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
}
@-webkit-keyframes fd_sx {
  0% {
    -webkit-transform: scale(1.2);
    transform: scale(1.2);
  }
  50% {
    -webkit-transform: scale(2);
    transform: scale(2);
  }
  100% {
    -webkit-transform: scale(1.2);
    transform: scale(1.2);
  }
}

#n_dsj {
  background: url(../../../assets/images/bg_dsj.png) center top repeat-y;
  padding-top: 125px;
  margin-bottom: 80px;
  position: relative;
}
// #n_dsj .content {
  // background: url(../../../assets/images/bg_dsj_first.png) center 10px no-repeat;
// }
#n_dsj dl {
  position: relative;
  overflow: hidden;
}
#n_dsj .dd {
  display: block;
  width: 100%;
  position: relative;
  min-height: 208px;
  // margin-bottom: 55px;
}
// #n_dsj .dd:last-child {
//   margin-bottom: 40px;
// }
#n_dsj .dd .pic_box {
  position: absolute;
  top: 0;
  right: 50%;
  margin-right: -7px;
  width: 50%;
  background: url(../../../assets/images/icon_dsj.png) right center no-repeat;
  transition: 0.5s;
  -webkit-transition: 0.5s;
}
#n_dsj .dd .pic_box .pic {
  float: right;
  width: 400px;
  height: 200px;
  margin-right: 9%;
  display: block;
  border: 1px solid rgb(231, 233, 235);
}
#n_dsj .dd .text_box {
  margin-left: 641px;
  width: 559px;
  padding-top: 33px;
}
#n_dsj .dd .text_box .box {
  position: relative;
}
#n_dsj .dd .text_box h1 {
  font-size: 23px;
  font-weight: 400;
  display: block;
  color: #525354;
  margin-bottom: 20px;
  font-family: arial;
}
#n_dsj .dd .text_box h1 small {
  font-size: 15px;
  font-weight: bold;
  margin-left: 3px;
  font-family: pinfang, "微软雅黑";
}
#n_dsj .dd .text_box .ar_article {
  text-align: left;
  font-size: 13px;
  line-height: 28px;
  color: #6a6b6c;
}
#n_dsj .dd .text_box .ar_article p {
  font-size: 13px;
  line-height: 28px;
  color: #6a6b6c;
  width: 465px;
}
// #n_dsj dd:nth-child(2n) {
// }
#n_dsj dd:nth-child(2n) .pic_box {
  right: auto;
  margin-right: 0;
  left: 50%;
  margin-left: -7px;
  // background: url(../../../assets/images/icon_dsj.png) left center no-repeat;
}
#n_dsj dd:nth-child(2n) .pic_box .pic {
  margin-right: 0;
  float: none;
  margin-left: 9%;
}
#n_dsj dd:nth-child(2n) .text_box {
  margin: 0;
  text-align: right !important;
}
#n_dsj dd:nth-child(2n) .text_box * {
  text-align: right !important;
  margin-left: 30px;

}
#n_dsj dd:nth-child(2n):hover .pic_box {
  top: 10px;
  background: url(../../../assets/images/icon_dsj_hover.png) left center no-repeat;
}
#n_dsj .dd:hover .pic_box {
  top: 10px;
  background: url(../../../assets/images/icon_dsj_hover.png) right center no-repeat;
}
#n_dsj dd:nth-child(2n) .pic_box {
  right: auto;
  margin-right: 0;
  left: 50%;
  margin-left: -7px;
  background: url(../../../assets/images/icon_dsj.png) left center no-repeat;
}

// #n_dsj .dd:hover {
// }
#n_dsj .dd:hover h1 {
  color: #d80930;
}
#dsj_hide_box {
  display: none;
}
#bnt_dsj_more {
  margin-top: 40px;
}
#bnt_dsj_more a {
  position: relative;
  background: #d80930;
  cursor: pointer;
  display: block;
  margin: 0px auto;
  height: 40px;
  line-height: 40px;
  width: 160px;
  text-align: center;
  font-size: 14px;
  color: #fff;
}
#bnt_dsj_more a h1 {
  display: none;
  position: absolute;
  left: 0;
  top: 0;
  background: #d80930;
  height: 40px;
  width: 100%;
  text-align: center;
  font-size: 14px;
  color: #fff;
}
#bnt_dsj_more.on h1 {
  display: block;
}
</style>
